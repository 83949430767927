import { z } from 'zod';

export enum DiscountType {
    CAMPAIGN = 'discount_campaign',
    CODE = 'discount_code',
}

export enum DiscountState {
    APPLIED = 'applied',
    APPLY_PROMPT = 'apply_prompt',
    INVALID = 'invalid',
    OVERRIDE_PROMPT = 'override_prompt',
}

export enum DiscountAction {
    APPLY = 'apply',
    PROMPT = 'prompt',
}

const BaseResponseSchema = <State extends DiscountState, Translations extends z.ZodRawShape>(state: State, translations: z.ZodObject<Translations>) => z.object({
    data: z.object({
        countdownDate: z.string().optional(),
        image: z.string().optional(),
        texts: translations,
    }),
    state: z.literal(state),
    type: z.nativeEnum(DiscountType),
});

const DiscountApplyPromptResponseSchema = BaseResponseSchema(
    DiscountState.APPLY_PROMPT,
    z.object({
        apply_later_description: z.string(),
        apply_later_title: z.string(),
        cancel_button: z.string(),
        confirm_button: z.string(),
        description: z.string(),
        title: z.string(),
    }),
);

const DiscountOverridePromptResponseSchema = BaseResponseSchema(
    DiscountState.OVERRIDE_PROMPT,
    z.object({
        apply_later_description: z.string(),
        apply_later_title: z.string(),
        cancel_button: z.string(),
        confirm_button: z.string(),
        description: z.string().optional(),
        override_prompt: z.string(),
        title: z.string(),
    }),
);

export const DiscountAppliedResponseSchema = BaseResponseSchema(
    DiscountState.APPLIED,
    z.object({
        acknowledge_button: z.string(),
        banner_text: z.string().optional(),
        countdown_text: z.string().optional(),
        description: z.string(),
        title: z.string(),
    }),
);

const DiscountInvalidResponseSchema = BaseResponseSchema(
    DiscountState.INVALID,
    z.object({
        acknowledge_button: z.string(),
        description: z.string().nullable(),
        title: z.string(),
    }),
);

const DiscountResponseSchema = z.union([
    DiscountApplyPromptResponseSchema,
    DiscountOverridePromptResponseSchema,
    DiscountAppliedResponseSchema,
    DiscountInvalidResponseSchema,
]);

export type DiscountApplyPromptResponse = z.infer<typeof DiscountApplyPromptResponseSchema>;
export type DiscountOverridePromptResponse = z.infer<typeof DiscountOverridePromptResponseSchema>;
export type DiscountAppliedResponse = z.infer<typeof DiscountAppliedResponseSchema>;
export type DiscountInvalidResponse = z.infer<typeof DiscountInvalidResponseSchema>;
export type DiscountResponse = z.infer<typeof DiscountResponseSchema>;

export default DiscountResponseSchema;
