import { z } from 'zod';
import { localStorage } from '../../../js/util/storage';
import type { DiscountData } from '../Provider/DiscountDataProvider';
import DiscountResponseSchema, { DiscountAction } from '../../general/query/Discount/Schema/DiscountResponse';

enum DiscountDataKey {
    CLEAR_DISCOUNT_DATA_COOKIE_KEY = 'CLEAR_DISCOUNT_CAMPAIGN_DATA',
    LOCAL_STORAGE_KEY = 'discount-data',
}

export type PersistedDiscountData = DiscountData & { updatedAt: string };

const clearPersistedDiscountData = () => {
    try {
        localStorage.removeItem(DiscountDataKey.LOCAL_STORAGE_KEY);
    } catch (error) {
        // Local storage is not writable.
    }
};

const DiscountDataSchema = z.object({
    action: z.nativeEnum(DiscountAction),
    dc: z.string(),
    medium: z.string().optional(),
    response: DiscountResponseSchema,
    updatedAt: z.string(),
});

const getPersistedDiscountData = (): PersistedDiscountData | null => {
    try {
        const discountData = DiscountDataSchema.safeParse(JSON.parse(localStorage.getItem(DiscountDataKey.LOCAL_STORAGE_KEY) ?? ''));

        if (discountData.success) {
            return discountData.data;
        }

        clearPersistedDiscountData();

        return null;
    } catch (error) {
        return null;
    }
};

const saveDiscountDataToLocalStorage = (data: DiscountData | null): void => {
    try {
        localStorage.setItem(DiscountDataKey.LOCAL_STORAGE_KEY, JSON.stringify({
            ...data,
            updatedAt: new Date(),
        }));
    } catch (error) {
        // Local storage is not writable.
    }
};

const isPersistedDiscountDataStale = (persistedDiscountData: PersistedDiscountData | null): boolean => {
    if (!persistedDiscountData) {
        return false;
    }

    const datesAreOnSameDay = (first: Date, second: Date) => first.getFullYear() === second.getFullYear()
        && first.getMonth() === second.getMonth()
        && first.getDate() === second.getDate();

    return !datesAreOnSameDay(new Date(persistedDiscountData.updatedAt), new Date());
};

export {
    clearPersistedDiscountData,
    getPersistedDiscountData,
    saveDiscountDataToLocalStorage,
    isPersistedDiscountDataStale,
    DiscountDataKey,
};
